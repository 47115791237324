<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="show"
      width="40%"
      :before-close="closeFn"
    >
      <el-form
        ref="editWordDetailformRef"
        :model="editWordDetailForm"
        label-width="120px"
      >
        <el-form-item label="词库名称：">
          <el-input
            v-model="editWordDetailForm.name"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="组别：" prop="level">
          <el-select
            placeholder="请选择"
            v-model="editWordDetailForm.level"
            style="width: 100%"
          >
            <el-option
              v-for="item in dict_Group"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赛季：" prop="seasons">
          <el-select
            multiple
            placeholder="请选择"
            style="width: 100%"
            v-model="editWordDetailForm.seasons"
            clearable
          >
            <el-option
              v-for="item in dict_season"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标识：" prop="flag">
          <el-select
            placeholder="请选择"
            v-model="editWordDetailForm.flag"
            style="width: 100%"
          >
            <el-option
              v-for="item in dict_thesaurus_flag"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="editWordDetailForm.note"
            placeholder="请输入"
            style="width: 90%"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="state">
          <el-select
            placeholder="请选择"
            v-model="editWordDetailForm.state"
            style="width: 100%"
          >
            <el-option
              v-for="item in dict_wordStorageType"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFn">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { saveWordGroupDetail, addWordGroupDetail } from '@/http/api'
export default {
  name: 'wordGroupAdd',
  props: {
    show: Boolean,
    editWordDetailForm: Object,
    title: String
  },
  data () {
    return {
      dict_wordStorageType: [
        {
          dictCode: 1,
          dictLabel: '启用',
          dictValue: true
        },
        {
          dictCode: 2,
          dictLabel: '停用',
          dictValue: false
        }
      ],
      dict_thesaurus_flag: this.$userInfo.getDataList(this, 'thesaurus_flag').then(value => { this.dict_thesaurus_flag = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_Group: this.$userInfo.dict_group()
    }
  },

  methods: {
    submitFn () {
      if (this.editWordDetailForm.id) { // 修改词库
        saveWordGroupDetail(this.editWordDetailForm).then((res) => {
          this.$emit('submit')
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else { // 添加词库
        addWordGroupDetail(this.editWordDetailForm).then((res) => {
          this.$emit('submit')
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    closeFn () {
      this.$refs.editWordDetailformRef.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.add {
  font-size: 22px;
  color: #409eff;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  top: 4px;
}
</style>
