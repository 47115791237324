<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术管理</el-breadcrumb-item>
      <el-breadcrumb-item>词库管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="词库名称" prop="name">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组别" prop="level">
              <el-select
                placeholder="请选择"
                v-model="queryForm.level"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_Group"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="赛季：" prop="seasons">
              <el-select
                multiple
                placeholder="请选择"
                style="width: 100%"
                @change="$forceUpdate()"
                v-model="queryForm.seasons"
                clearable
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="searchFn()"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="5">
          <span class="title_class">单词管理</span>
        </el-col>
        <el-col :span="19" style="text-align: right">
          <el-button type="primary" @click="editDistrictMessage('add')"
            >新增词库+</el-button
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="词库名" prop="name"> </el-table-column>
        <el-table-column label="赛季" prop="seasons" width="100px">
          <template slot-scope="scope">
            <span v-if="scope.row.seasons">{{
              String(scope.row.seasons)
            }}</span>
            <span v-else>未设置</span>
          </template>
        </el-table-column>
        <el-table-column
          label="组别"
          prop="level"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column
          label="标签"
          prop="flag"
          :formatter="flagFormatter"
        ></el-table-column>
        <el-table-column label="状态" prop="state">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state === true" size="mini"
              >已启用</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="scope.row.state === false"
              size="mini"
              >已停用</el-tag
            >
            <span v-else size="mini">-</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="note"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="editDistrictMessage('edit', scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-setting"
              size="mini"
              style="color: #e6a23d"
              @click="turnToSetWordGroupDetail(scope.row.id)"
              >设置</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="copyDistrictMessage(scope.row)"
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <wordGroupAdd
      :show="editChineseCharactersDialogVisible"
      :title="editTitle"
      :editWordDetailForm="editWordDetailForm"
      @close="closeFn"
      @submit="submitData"
    ></wordGroupAdd>
  </div>
</template>
<script>
import { getWordGroupList, getWordGroupDetailData, copyWordGrop } from '@/http/api'
import wordGroupAdd from './components/add_word_group'
export default {
  components: {
    wordGroupAdd
  },
  data () {
    return {
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      editChineseCharactersDialogVisible: false,
      playVoiceUrl: '',
      dict_syllables: this.$userInfo.dict_syllables(),
      dict_istoefl: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: 1
        },
        {
          dictCode: 0,
          dictLabel: '否',
          dictValue: 0
        }
      ],
      editWordDetailForm: {},
      dataList: [],
      total: 0,
      dict_Group: this.$userInfo.dict_group(),
      dict_wordStorageType: this.$userInfo.dict_wordStorage(),
      dict_thesaurus_flag: this.$userInfo.getDataList(this, 'thesaurus_flag').then(value => { this.dict_thesaurus_flag = value }),
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      editTitle: ''
    }
  },
  created () {
    this.getWordGroupList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取单词列表
    getWordGroupList () {
      getWordGroupList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getWordGroupList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getWordGroupList()
    },
    // 查询
    searchFn () {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 10
      this.getWordGroupList()
    },
    // 重置
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.searchFn()
    },
    // 修改单词
    editDistrictMessage (type, rowData) {
      if (type === 'edit') {
        this.editTitle = '修改词库'
        getWordGroupDetailData(rowData.id).then((res) => {
          this.editWordDetailForm = res.data
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        this.editTitle = '新增词库'
      }
      this.editChineseCharactersDialogVisible = true
    },
    closeFn () {
      this.editWordDetailForm = {}
      this.editChineseCharactersDialogVisible = false
    },
    // 保存信息
    submitData () {
      this.closeFn()
      this.$message.success('保存成功')
      this.reset()
    },
    // 跳转词库详情
    turnToSetWordGroupDetail (rowId) {
      this.$router.push({
        path: '/academic_Management/academic_font_management/academic_wordgroup_detail',
        query: {
          groupId: rowId
        }
      })
    },
    // 复制一份词库
    copyDistrictMessage (rowData) {
      copyWordGrop({ id: rowData.id }).then((res) => {
        this.getWordGroupList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    flagFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_thesaurus_flag) {
        if (Object.hasOwnProperty.call(this.dict_thesaurus_flag, key)) {
          const element = this.dict_thesaurus_flag[key]
          if (element.dictValue === data.flag) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === data.level) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>
